import { useNavigate } from "react-router";
import Navbar from "../components/Navbar";
import useParcelStore from "../stores/parcelStore";
import React, { useEffect } from "react";
import moment from "moment";
import useAuthStore from "../stores/authStore";

const UserDashboard = () => {
  const navigate = useNavigate();
  const parcelData = useParcelStore((state) => state.parcel);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const setParcel = useParcelStore((state) => state.setExpandedParcel);

  // -------- Redirect user if logged in -----------------
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/signin");
    }
  }, [isLoggedIn, navigate]);

  const handleCreate = () => {
    navigate("/createparcel");
  };

  const handleManage = (data) => {
    setParcel(data);
    navigate("/expanded", { state: { data } });
  };

  return (
    <>
      <Navbar />

      <div class="container mx-auto max-w-[1000px] flex flex-wrap mt-10">
        <div class="w-full  p-4 bg-white " style={{ color: "#082D38" }}>
          <div class="p-2 space-y-4 md:space-y-6 sm:p-8">
            {/* ------- First Row ------ */}
            <div className="w-full flex justify-between space-x-4 ">
              <div className="w-[80%] flex items-center ">
                <div>
                  <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                    Total Parcel:
                  </h1>

                  <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-4xl dark:text-white">
                    {parcelData?.length}
                  </h1>
                </div>
              </div>

              <div className="w-[80%] h-[100px] flex items-center justify-end">
                <button className="createBtn" onClick={handleCreate}>
                  Send Parcel
                </button>
              </div>
            </div>
          </div>

          <hr />

          <div className="relative overflow-x-auto ">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr style={{ backgroundColor: "#082d38" }}>
                  <th scope="col" class="px-6 py-3">
                    No
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Tracking Code
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Status
                  </th>

                  <th scope="col" class="px-6 py-3">
                    Delivery Date
                  </th>

                  <th scope="col" class="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <React.Fragment>
                  {parcelData?.map((item, index) => (
                    <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                      <td class="px-6 py-4">{index + 1}</td>
                      <td class="px-6 py-4">{item?.receiverEmail}</td>
                      <td class="px-6 py-4">{item?.trackingId}</td>
                      <td class="px-6 py-4">{item?.status}</td>
                      <td class="px-6 py-4">
                        {moment(item?.expectedDeliveryDate).format("ll")}
                      </td>
                      <td class="px-6 py-4">
                        <button
                          className="userManageBtn"
                          onClick={() => handleManage(item)}
                        >
                          Expand
                        </button>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDashboard;
