import {
  Activity,
  AtSign,
  Calendar,
  Diameter,
  DollarSign,
  FastForward,
  MessageCircle,
  Plane,
  User,
  Weight,
} from "lucide-react";
import Navbar from "../components/Navbar";
import { useEffect, useState } from "react";
import MapComponent from "../components/MapComponent";
import moment from "moment";
import Loader from "../components/Loader";
import useParcelStore from "../stores/parcelStore";
import MessageAlert from "../components/MessageAlert";
import { useNavigate } from "react-router";
import useAuthStore from "../stores/authStore";

const ManageParcel = () => {
  const navigate = useNavigate();
  const parcelData = useParcelStore((state) => state.parcel);
  const storeParcel = useParcelStore((state) => state.setParcel);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const [isLoading, setIsLoading] = useState(false);
  const [parcel, setParcel] = useState(parcelData || []);
  const [shippingHistory, setShippingHistory] = useState(
    JSON.parse(parcelData.shippingHistory || "[]")
  );

  const [message, setMessage] = useState("");
  const [messageID, setMessageID] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (parcelData.shippingHistory) {
      setShippingHistory(JSON.parse(parcelData.shippingHistory));
    }
  }, [parcel]);

  const [formData, setFormData] = useState({
    shipmentDate: "",
    deliveryDate: "",
    shippingStatus: "",
    currentLocation: "",
    trackingId: parcel.trackingId,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // -------- Redirect user if logged in -----------------
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/signin");
    } else if (isLoggedIn && User.role === "user") {
      navigate("/signin");
    }
  }, [isLoggedIn, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (formData.currentLocation && formData.shippingStatus === "") {
        throw new Error("Cannot update current location without status");
      }

      setIsLoading(true);
      const response = await fetch(`https://bloomcargo-api.bloomcargo.com/api/parcels/update`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (result.newParcel) {
        setParcel(result?.newParcel);
        storeParcel(result?.newParcel);
        setFormData({
          shipmentDate: "",
          deliveryDate: "",
          shippingStatus: "",
          currentLocation: "",
          trackingId: parcel.trackingId,
        });
        setIsLoading(false);

        setMessage("Updated!");
        setMessageID("successAlert");
        setShowMessage(true);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      setMessage(error.message);
      setMessageID("declineAlert");
      setShowMessage(true);
      setIsLoading(false);
    }
  };

  // Hide message after 3 seconds
  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showMessage]);

  return (
    <>
      <Navbar />

      {showMessage ? <MessageAlert data={{ message, messageID }} /> : ""}

      <div class="container  mx-auto max-w-[1200px] flex justify-between flex-wrap mt-10">
        {/* ------ Parcel Details ---------- */}
        <div
          class="w-[80%]  sm:w-1/2  p-4 bg-white parcelCol1"
          style={{ color: "rgb(108 112 113)" }}
        >
          <h1 className="parcelHeaders">Sender's Information</h1>

          {/* Row */}
          <div className="flex items-center mt-2 mb-2">
            <User size={30} />
            <div className="ml-4">
              <h3>Sender</h3>
              <strong>
                {" "}
                <span>{parcel?.senderFullname}</span>
              </strong>
            </div>
          </div>

          <hr />
          {/* Row */}
          <div className="flex items-center mt-2">
            <AtSign size={30} />
            <div className="ml-4">
              <h3>Sender's Email</h3>
              <strong>
                {" "}
                <span>{parcel?.senderEmail}</span>
              </strong>
            </div>
          </div>

          <hr />
          {/* Row */}
          <div className="flex items-center mt-2">
            <Plane size={30} />
            <div className="ml-4">
              <h3>Origin/Departure</h3>
              <strong>
                {" "}
                <span>{parcel?.pickupCountry}</span>
              </strong>
            </div>
          </div>

          <hr />
          {/* Row */}
          <div className="flex items-center mt-2">
            <Calendar size={30} />
            <div className="ml-4">
              <h3>Shipment Date</h3>
              <strong>
                {" "}
                <span>{moment(parcel?.shipmentDate).format("ll")}</span>
              </strong>
            </div>
          </div>

          {/* --------------------------------------------------- */}
          <h1 className="parcelHeaders mt-4">Receiver's Information</h1>
          {/* Row */}
          <div className="flex items-center mt-2 mb-2">
            <User size={30} />
            <div className="ml-4">
              <h3>Receiver</h3>
              <strong>
                {" "}
                <span>{parcel?.receiverFullname}</span>
              </strong>
            </div>
          </div>

          <hr />
          {/* Row */}
          <div className="flex items-center mt-2">
            <AtSign size={30} />
            <div className="ml-4">
              <h3>Receiver's Email</h3>
              <strong>
                {" "}
                <span>{parcel?.receiverEmail}</span>
              </strong>
            </div>
          </div>

          <hr />
          {/* Row */}
          <div className="flex items-center mt-2">
            <Plane size={30} />
            <div className="ml-4">
              <h3>Delivery Location</h3>
              <strong>
                {" "}
                <span>{parcel?.deliveryCountry}</span>
              </strong>
            </div>
          </div>

          {/* --------------------------------------------------- */}
          <h1 className="parcelHeaders mt-4">Parcel Details</h1>
          {/* Row */}
          <div className="flex items-center mt-2 mb-2">
            <MessageCircle size={30} />
            <div className="ml-4">
              <h3>Parcel Description</h3>
              <strong>
                {" "}
                <span>{parcel?.parcelDescription}</span>
              </strong>
            </div>
          </div>

          <hr />
          {/* Row */}
          <div className="flex items-center mt-2">
            <Diameter size={30} />
            <div className="ml-4">
              <h3>Dimensions</h3>
              <strong>
                {" "}
                <span>{parcel?.parcelDimensions}</span>
              </strong>
            </div>
          </div>

          <hr />
          {/* Row */}
          <div className="flex items-center mt-2">
            <Weight size={30} />
            <div className="ml-4">
              <h3>Parcel Weight</h3>
              <strong>
                {" "}
                <span>{Number(parcel?.parcelWeight).toLocaleString()}kg</span>
              </strong>
            </div>
          </div>

          <hr />
          {/* Row */}
          <div className="flex items-center mt-2">
            <DollarSign size={30} />
            <div className="ml-4">
              <h3>Parcel Declared Value</h3>
              <strong>
                {" "}
                <span>${Number(parcel?.parcelValue).toLocaleString()}</span>
              </strong>
            </div>
          </div>

          <hr />
          {/* Row */}
          <div className="flex items-center mt-2">
            <Calendar size={30} />
            <div className="ml-4">
              <h3>Expected Delivery Date</h3>
              <strong>
                {" "}
                <span>
                  {parcel?.expectedDeliveryDate
                    ? moment(parcel?.expectedDeliveryDate).format("ll")
                    : ""}
                </span>
              </strong>
            </div>
          </div>

          {/* --------------------------------------------------- */}
          <h1 className="parcelHeaders mt-4">Shipment Status</h1>
          {/* Row */}
          <div className="flex items-center mt-2 mb-2">
            <FastForward size={30} />
            <div className="ml-4">
              <h3>Shipping speed</h3>
              <strong>
                {" "}
                <span>{parcel?.shippingSpeed}</span>
              </strong>
            </div>
          </div>

          <hr />
          {/* Row */}
          <div className="flex items-center mt-2 mb-2">
            <Activity
              size={30}
              color={`${parcel?.status === "Seized" ? "#f46f6f" : "gray"} `}
            />
            <div className="ml-4">
              <h3>Status</h3>
              <strong>
                {" "}
                <span
                  style={{
                    color: `${parcel?.status === "Seized" ? "red" : ""} `,
                  }}
                >
                  {parcel?.status}
                </span>
              </strong>
            </div>
          </div>

          {/* --------------------------------------------------- */}
          <h1 className="parcelHeaders">Shipping History</h1>
          {/* Row */}
          <div className="flex items-center mt-2 mb-2">
            <ol class="relative text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400 ml-6">
              {shippingHistory
                ? shippingHistory?.map(({ location, status, date }) => (
                    <li class="mb-10 ms-6">
                      {status === "Seized" ? (
                        <span class="absolute flex items-center justify-center w-8 h-8 bg-red-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                          <svg
                            class="w-3.5 h-3.5 text-red-500 dark:text-red-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 16"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M2 2l12 12M14 2L2 14"
                            />
                          </svg>
                        </span>
                      ) : (
                        <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                          <svg
                            class="w-3.5 h-3.5 text-green-500 dark:text-green-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </span>
                      )}

                      <strong>
                        <span>{location}</span>
                      </strong>
                      <p class="text-sm">{moment(date).format("ll")}</p>
                      <h3
                        class="font-medium leading-tight"
                        style={{
                          color: `${status === "Seized" ? "red" : ""} `,
                        }}
                      >
                        {status === "newLocation" ? "In Transit" : status }
                      </h3>
                    </li>
                  ))
                : ""}
            </ol>
          </div>

          {/* =========== Map ================= */}

          <MapComponent data={parcel} />
        </div>

        {/* ------ Map Details ---------- */}
        <div
          class="w-[46%]     p-4 bg-white parcelCol2"
          style={{ color: "rgb(108 112 113)" }}
        >
          <h1 className="parcelHeaders" style={{ backgroundColor: "#082d38" }}>
            Manage Parcel
          </h1>

          <form className="mt-6">
            <div>
              <label
                for="password"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Shipment Date
              </label>
              <input
                type="date"
                name="shipmentDate"
                placeholder="Full name"
                class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={formData.shipmentDate}
                onChange={handleChange}
              />
            </div>

            <div>
              <label
                for="password"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-6"
              >
                Expected Delivery Date
              </label>
              <input
                type="date"
                name="deliveryDate"
                placeholder="Full name"
                class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={formData.deliveryDate}
                onChange={handleChange}
              />
            </div>

            <div className=" ">
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-6"
              >
                Shipping Status
              </label>

              <select
                class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                name={"shippingStatus"}
                value={formData.shippingStatus}
                onChange={handleChange}
              >
                <option value="">-- select --</option>
                <option value="Picked Up">Picked Up</option>
                <option value="In Transit">In Transit</option>
                <option value="Delivered">Delivered</option>
                <option value="Seized">Seized</option>
                <option value="newLocation">newLocation</option>
              </select>
            </div>

            <div>
              <label
                for="password"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-6"
              >
                Current Location
              </label>
              <input
                type="text"
                name="currentLocation"
                placeholder="Address of the current location"
                class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={formData.currentLocation}
                onChange={handleChange}
              />
            </div>

            <hr className="mt-6" />
            <div className="flex justify-end mt-6">
              <button
                className="manageBtn"
                style={{ backgroundColor: "#f57539" }}
                onClick={handleSubmit}
              >
                {isLoading ? <Loader /> : "Update"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ManageParcel;
