import { useState } from "react";
import logo from "../assets/logo.jpg";
import { Menu, User } from "lucide-react";
import useAuthStore from "../stores/authStore";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const logout = useAuthStore((state) => state.logout);
  const profile = useAuthStore((state) => state.user);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <nav class="navbar border-gray-200 dark:bg-gray-900">
      <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a
          href="https://bloomcargo.com/"
          class="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <img src={logo} class="h-8" alt="Logo" />
          <span
            class="self-center text-xl font-semibold whitespace-nowrap"
            style={{ color: "#fff" }}
          >
            Bloomcargo
          </span>
        </a>

        {!profile ? (
          ""
        ) : (
          <div className="relative inline-block text-left">
            <User
              onClick={toggleDropdown}
              color="#fff"
              size={30}
              style={{ cursor: "pointer" }}
            />
            {isOpen && (
              <div
                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
              >
                <div className="py-1" role="none">
                  <span
                    onClick={handleLogout}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    style={{ cursor: "pointer" }}
                  >
                    Logout
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </nav>
  );
}
