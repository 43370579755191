import {
  Activity,
  AtSign,
  Calendar,
  Diameter,
  DollarSign,
  FastForward,
  MessageCircle,
  Plane,
  User,
  Weight,
} from "lucide-react";
import Navbar from "../components/Navbar";
import { useEffect, useState } from "react";
import MapComponent from "../components/MapComponent";
import useParcelStore from "../stores/parcelStore";
import moment from "moment";
import { useNavigate } from "react-router";

const Parcel = () => {
  const navigate = useNavigate();
  const setParcel = useParcelStore((state) => state.setParcel);
  const parcel = useParcelStore((state) => state.parcel);
  const [parcelState, setParcelState] = useState(parcel);
  const ShipHistory = parcel?.shippingHistory
    ? JSON.parse(parcel.shippingHistory)
    : [];

  // -------- Redirect user if logged in -----------------
  useEffect(() => {
    if (parcel.length < 1) {
      navigate("/track");
    }
  }, [parcel, navigate]);

  useEffect(() => {
    try {
      const refresh = async () => {
        const response = await fetch(
          `https://bloomcargo-api.bloomcargo.com/api/parcels/track`,
          {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({ trackingCode: parcel.trackingId }),
          }
        );

        const result = await response.json();

        if (result.parcel) {
          setParcel(result.parcel);
        }
      };
      refresh();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      <Navbar />

      <div class="container  mx-auto max-w-[1200px] flex justify-between flex-wrap mt-10">
        {/* ------ Parcel Details ---------- */}
        <div
          class="w-[80%]  sm:w-1/2  p-4 bg-white parcelCol1"
          style={{ color: "rgb(108 112 113)" }}
        >
          <h1 className="parcelHeaders">Sender's Information</h1>

          {/* Row */}
          <div className="flex items-center mt-2 mb-2">
            <User size={30} />
            <div className="ml-4">
              <h3>Sender</h3>
              <strong>
                {" "}
                <span>{parcelState?.senderFullname}</span>
              </strong>
            </div>
          </div>

          <hr />
          {/* Row */}
          <div className="flex items-center mt-2">
            <AtSign size={30} />
            <div className="ml-4">
              <h3>Sender's Email</h3>
              <strong>
                {" "}
                <span>{parcelState?.senderEmail}</span>
              </strong>
            </div>
          </div>

          <hr />
          {/* Row */}
          <div className="flex items-center mt-2">
            <Plane size={30} />
            <div className="ml-4">
              <h3>Origin/Departure</h3>
              <strong>
                {" "}
                <span>{parcelState?.pickupCountry}</span>
              </strong>
            </div>
          </div>

          <hr />
          {/* Row */}
          <div className="flex items-center mt-2">
            <Calendar size={30} />
            <div className="ml-4">
              <h3>Shipment Date</h3>
              <strong>
                {" "}
                <span>{moment(parcelState?.shipmentDate).format("ll")}</span>
              </strong>
            </div>
          </div>

          {/* --------------------------------------------------- */}
          <h1 className="parcelHeaders mt-4">Receiver's Information</h1>
          {/* Row */}
          <div className="flex items-center mt-2 mb-2">
            <User size={30} />
            <div className="ml-4">
              <h3>Receiver</h3>
              <strong>
                {" "}
                <span>{parcelState?.receiverFullname}</span>
              </strong>
            </div>
          </div>

          <hr />
          {/* Row */}
          <div className="flex items-center mt-2">
            <AtSign size={30} />
            <div className="ml-4">
              <h3>Receiver's Email</h3>
              <strong>
                {" "}
                <span>{parcelState?.receiverEmail}</span>
              </strong>
            </div>
          </div>

          <hr />
          {/* Row */}
          <div className="flex items-center mt-2">
            <Plane size={30} />
            <div className="ml-4">
              <h3>Delivery Location</h3>
              <strong>
                {" "}
                <span>{parcelState?.deliveryCountry}</span>
              </strong>
            </div>
          </div>

          {/* --------------------------------------------------- */}
          <h1 className="parcelHeaders mt-4">Parcel Details</h1>
          {/* Row */}
          <div className="flex items-center mt-2 mb-2">
            <MessageCircle size={30} />
            <div className="ml-4">
              <h3>Parcel Description</h3>
              <strong>
                {" "}
                <span>{parcelState?.parcelDescription}</span>
              </strong>
            </div>
          </div>

          <hr />
          {/* Row */}
          <div className="flex items-center mt-2">
            <Diameter size={30} />
            <div className="ml-4">
              <h3>Dimensions</h3>
              <strong>
                {" "}
                <span>{parcelState?.parcelDimensions}</span>
              </strong>
            </div>
          </div>

          <hr />
          {/* Row */}
          <div className="flex items-center mt-2">
            <Weight size={30} />
            <div className="ml-4">
              <h3>Parcel Weight</h3>
              <strong>
                {" "}
                <span>
                  {Number(parcelState?.parcelWeight).toLocaleString()}kg
                </span>
              </strong>
            </div>
          </div>

          <hr />
          {/* Row */}
          <div className="flex items-center mt-2">
            <DollarSign size={30} />
            <div className="ml-4">
              <h3>Parcel Declared Value</h3>
              <strong>
                {" "}
                <span>
                  ${Number(parcelState?.parcelValue).toLocaleString()}
                </span>
              </strong>
            </div>
          </div>

          <hr />
          {/* Row */}
          <div className="flex items-center mt-2">
            <Calendar size={30} />
            <div className="ml-4">
              <h3>Expected Delivery Date</h3>
              <strong>
                {" "}
                <span>
                  {parcel?.expectedDeliveryDate
                    ? moment(parcelState?.expectedDeliveryDate).format("ll")
                    : ""}
                </span>
              </strong>
            </div>
          </div>

          {/* --------------------------------------------------- */}
          <h1 className="parcelHeaders mt-4">Shipment Status</h1>
          {/* Row */}
          <div className="flex items-center mt-2 mb-2">
            <FastForward size={30} />
            <div className="ml-4">
              <h3>Shipping speed</h3>
              <strong>
                {" "}
                <span>{parcelState?.shippingSpeed}</span>
              </strong>
            </div>
          </div>

          <hr />
          {/* Row */}
          <div className="flex items-center mt-2 mb-2">
            <Activity
              size={30}
              color={`${
                parcelState?.status === "Seized" ? "#f46f6f" : "gray"
              } `}
            />
            <div className="ml-4">
              <h3>Status</h3>
              <strong>
                {" "}
                <span
                  style={{
                    color: `${parcelState?.status === "Seized" ? "red" : ""} `,
                  }}
                >
                  {parcelState?.status}
                </span>
              </strong>
            </div>
          </div>
        </div>

        {/* ------ Map Details ---------- */}
        <div
          class="w-[46%]     p-4 bg-white parcelCol2"
          style={{ color: "rgb(108 112 113)" }}
        >
          {/* --------------------------------------------------- */}
          <h1 className="parcelHeaders">Shipping History</h1>
          {/* Row */}
          <div className="flex items-center mt-2 mb-2">
            <ol class="relative text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400 ml-6">
              {ShipHistory?.map(({ location, status, date }) => (
                <li class="mb-10 ms-6">
                  {status === "Seized" ? (
                    <span class="absolute flex items-center justify-center w-8 h-8 bg-red-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                      <svg
                        class="w-3.5 h-3.5 text-red-500 dark:text-red-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 16 16"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M2 2l12 12M14 2L2 14"
                        />
                      </svg>
                    </span>
                  ) : (
                    <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                      <svg
                        class="w-3.5 h-3.5 text-green-500 dark:text-green-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 16 12"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M1 5.917 5.724 10.5 15 1.5"
                        />
                      </svg>
                    </span>
                  )}
                  <strong>
                    <span>{location}</span>
                  </strong>
                  <p class="text-sm">{moment(date).format("ll")}</p>
                  <h3
                    class="font-medium leading-tight"
                    style={{
                      color: `${status === "Seized" ? "red" : ""} `,
                    }}
                  >
                    {status}
                  </h3>
                </li>
              ))}
            </ol>
          </div>

          {/* =========== Map ================= */}

          <MapComponent data={parcel} />
        </div>
      </div>
    </>
  );
};

export default Parcel;
