import Navbar from "../components/Navbar";
import MessageAlert from "../components/MessageAlert";
import Loader from "../components/Loader";
import useAuthStore from "../stores/authStore";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useParcelStore from "../stores/parcelStore";

const CreateParcel = () => {
  const [formData, setFormData] = useState({
    senderFullname: "",
    senderEmail: "",
    receiverFullname: "",
    receiverEmail: "",
    pickupAddress: "",
    deliveryAddress: "",
    parcelDescription: "",
    parcelDimensions: "",
    parcelWeight: "",
    shippingSpeed: "",
    deliveryPreference: "",
    parcelValue: "",
    pickupCountry: "",
    deliveryCountry: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageID, setMessageID] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const setParcel = useParcelStore((state) => state.setParcel);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle Sign Up
  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      const response = await fetch(`https://bloomcargo-api.bloomcargo.com/api/parcels`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message);
      }

      setMessage(result.message);
      setMessageID("successAlert");
      setShowMessage(true);
      setIsLoading(false);
      setParcel(result.parcelList);
      navigate("/userdashboard");
    } catch (err) {
      setMessage(err.message);
      setMessageID("declineAlert");
      setShowMessage(true);

      setIsLoading(false);
    }
  };

  // Hide message after 3 seconds
  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showMessage]);

  return (
    <>
      <Navbar />
      {showMessage ? <MessageAlert data={{ message, messageID }} /> : ""}
      <div class="container mx-auto max-w-[800px] flex flex-wrap mt-10">
        <div class="w-full  p-4 bg-white " style={{ color: "#082D38" }}>
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Send Parcel
            </h1>
            <form class="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
              <h1 style={{ color: "#f57539", fontWeight: "600" }}>
                Sender’s Information
              </h1>

              <div>
                <label
                  for="password"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Full name
                </label>
                <input
                  type="text"
                  name="senderFullname"
                  placeholder="Full name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                  value={formData.senderFullname}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label
                  for="password"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email
                </label>
                <input
                  type="text"
                  name="senderEmail"
                  placeholder="name@company.com"
                  class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                  value={formData.senderEmail}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label
                  for="password"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Pickup Country
                </label>
                <input
                  type="text"
                  name="pickupCountry"
                  placeholder="Sender's country"
                  class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                  value={formData.pickupCountry}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label
                  for="password"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Pickup address
                </label>
                <textarea
                  name="pickupAddress"
                  placeholder="Pickup address"
                  class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                  value={formData.pickupAddress}
                  onChange={handleChange}
                />
              </div>

              <h1 style={{ color: "#f57539", fontWeight: "600" }}>
                Receiver's Information
              </h1>

              {/* ------- First Row ------ */}
              <div className="w-full flex justify-between space-x-4 ">
                <div className="w-[80%]  ">
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Full name
                  </label>
                  <input
                    type="text"
                    name="receiverFullname"
                    class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Full name"
                    required
                    value={formData.receiverFullname}
                    onChange={handleChange}
                  />
                </div>

                <div className="w-[80%] ">
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="receiverEmail"
                    class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com"
                    required
                    value={formData.receiverEmail}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div>
                <label
                  for="password"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Delivery Country
                </label>
                <input
                  type="text"
                  name="deliveryCountry"
                  placeholder="Receiver's country"
                  class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                  value={formData.deliveryCountry}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label
                  for="password"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Delivery address
                </label>
                <textarea
                  name="deliveryAddress"
                  placeholder="Please include postal code"
                  class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                  value={formData.deliveryAddress}
                  onChange={handleChange}
                />
              </div>

              <h1 style={{ color: "#f57539", fontWeight: "600" }}>
                Parcel Information
              </h1>

              <div>
                <label
                  for="password"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Parcel Description
                </label>
                <textarea
                  name="parcelDescription"
                  placeholder="e.g., clothing items or documents"
                  class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                  value={formData.parcelDescription}
                  onChange={handleChange}
                />
              </div>

              <div className="w-full flex justify-between space-x-4 ">
                <div className="w-[80%]  ">
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Parcel Dimensions (LxWxH cm)
                  </label>
                  <input
                    type="text"
                    name="parcelDimensions"
                    class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="e.g, 30x10x15"
                    required
                    value={formData.parcelDimensions}
                    onChange={handleChange}
                  />
                </div>

                <div className="w-[80%] ">
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Weight (kg)
                  </label>
                  <input
                    type="number"
                    name="parcelWeight"
                    class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Weight (kg)"
                    required
                    value={formData.parcelWeight}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div>
                <label
                  for="password"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Declared Value ($)
                </label>
                <input
                  type="number"
                  name="parcelValue"
                  placeholder="Value of parcel in Dollars"
                  class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                  value={formData.parcelValue}
                  onChange={handleChange}
                />
              </div>

              <h1 style={{ color: "#f57539", fontWeight: "600" }}>
                Delivery Options
              </h1>
              {/* ------- First Row ------ */}
              <div className="w-full flex justify-between space-x-4 ">
                <div className="w-[80%]  ">
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Shipping Speed
                  </label>

                  <select
                    class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                    name={"shippingSpeed"}
                    value={formData.shippingSpeed}
                    onChange={handleChange}
                  >
                    <option value="">-- select --</option>
                    <option value="Same-Day Delivery">Same-Day Delivery</option>
                    <option value="Next-Day Delivery">Next-Day Delivery</option>
                    <option value="Express Delivery">Express Delivery</option>
                    <option value="Standard Delivery">Standard Delivery</option>
                    <option value="Economy Delivery">Economy Delivery</option>
                  </select>
                </div>

                <div className="w-[80%] ">
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Delivery Preference
                  </label>

                  <select
                    class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                    name={"deliveryPreference"}
                    value={formData.deliveryPreference}
                    onChange={handleChange}
                  >
                    <option value="">-- select --</option>
                    <option value="Signature Required">
                      Signature Required
                    </option>
                    <option value="No-Contact Delivery">
                      No-Contact Delivery
                    </option>
                    <option value="Fragile or Special Handling">
                      Fragile Handling
                    </option>
                    <option value="Delivery Appointment">
                      Delivery Appointment
                    </option>
                  </select>
                </div>
              </div>

              <button
                type="submit"
                class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                style={{ backgroundColor: "#f57539" }}
              >
                {isLoading ? <Loader /> : "Send Parcel"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateParcel;
