import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const useParcelStore = create(
  devtools(
    persist(
      (set, get) => ({
        parcel: [],
        expandedParcel: [],

        setParcel: (userData) =>
          set({
            parcel: userData,
          }),

        setExpandedParcel: (userData) =>
          set({
            expandedParcel: userData,
          }),

        clearParcel: () =>
          set(() => ({
            parcel: [],
            expandedParcel: [],
          })),
      }),
      { name: "parcel" }
    )
  )
);

export default useParcelStore;
