import moment from "moment";
import Navbar from "../components/Navbar";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useParcelStore from "../stores/parcelStore";
import useAuthStore from "../stores/authStore";

const ParcelList = () => {
  const navigate = useNavigate();
  const [parcelList, setParcelList] = useState([]);
  const setParcel = useParcelStore((state) => state.setParcel);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const User = useAuthStore((state) => state.user);

  // -------- Redirect user if logged in -----------------
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/signin");
    } else if (isLoggedIn && User.role === "user") {
      navigate("/signin");
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    const handleFetchParcelList = async () => {
      try {
        const response = await fetch(
          `https://bloomcargo-api.bloomcargo.com/api/parcels/allparcel`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
            },
          }
        );

        const result = await response.json();

        setParcelList(result.parcel);
      } catch (error) {
        console.log(error);
      }
    };

    handleFetchParcelList();
  }, []);

  const handleManage = (data) => {
    setParcel(data);
    navigate("/manage", { state: { data } });
  };

  return (
    <>
      <Navbar />
      <div class="container mx-auto max-w-[1000px] flex flex-wrap mt-10">
        <div class="w-full  p-4 bg-white " style={{ color: "#082D38" }}>
          <div class="p-2 space-y-4 md:space-y-6 sm:p-8">
            <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              All Parcel
            </h1>

            <div class="relative overflow-x-auto ">
              <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="px-6 py-3">
                      No
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Email
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Tracking Code
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Status
                    </th>
                    {/* <th scope="col" class="px-6 py-3">
                      Shipment Date
                    </th> */}

                    <th scope="col" class="px-6 py-3">
                      Delivery Date
                    </th>

                    <th scope="col" class="px-6 py-3">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {parcelList?.map((data, index) => (
                      <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td class="px-6 py-4">{index + 1}</td>
                        <td class="px-6 py-4">{data?.receiverEmail}</td>
                        <td class="px-6 py-4">{data?.trackingId}</td>
                        <td class="px-6 py-4">{data?.status}</td>
                        {/* <td class="px-6 py-4">
                          {moment(data?.shipmentDate).format("ll")}
                        </td> */}
                        <td class="px-6 py-4">
                          {moment(data?.expectedDeliveryDate).format("ll")}
                        </td>
                        <td class="px-6 py-4">
                          <button
                            className="manageBtn"
                            onClick={() => handleManage(data)}
                          >
                            Manage
                          </button>
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParcelList;
